


































import {Component, Prop, Vue} from 'vue-property-decorator'
import {AuthRequest} from '@/model/request/AuthRequest'
import {InputAuthRequestSchema} from '@/schema/request/AuthRequest/InputAuthRequestSchema'

@Component
export default class SignInView extends Vue {
  schema = new InputAuthRequestSchema()
  request = new AuthRequest()

  created() {
    if (this.$auth.isLogged) {
      this.$nav.replace('/dashboard')
    }
  }
}
